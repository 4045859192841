import React from "react"
import { Box } from "@chakra-ui/core"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const TnpscCompetition = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "compbanner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box>
      <SEO title="TNPSC competition" />
      <Layout>
        <Box mt={3} maxW="800px" mx="auto">
          <Image fluid={data.banner.childImageSharp.fluid} />
        </Box>
        <iframe
          width="100%"
          height="800px"
          src="https://docs.google.com/forms/d/e/1FAIpQLScT4MImcAN7uTOSGk7K3dQy4oKZABSdG-N_Slzm8Lk7GKKHTg/viewform?embedded=true"
          style={{ maxWidth: "800px", margin: "auto" }}
        >
          Loading...
        </iframe>
      </Layout>
    </Box>
  )
}

export default TnpscCompetition
